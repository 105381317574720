@import 'common';
@import 'bootstrap/scss/close';
.close {
	opacity: 0.6;

	&:not(:disabled):not(.disabled) {
		@include hover-focus() {
			opacity: 0.95;
		}
	}
}
